import React from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'

import {
  StyledLink,
  StyledSpan,
  StyledH2,
  StyledP,
} from '../styled-components/base'
import CortizFitnessBanner from '../images/cortiz-banner.jpg'
import MondayMadness from '../images/groupsession.jpg'
import { useSpring, animated } from 'react-spring'
import { EndPoint } from '../components/nav/header-nav'

const HeroImage = styled.div`
  background-image: url(${CortizFitnessBanner});
  box-shadow: inset 0 0 0 2000px rgba(0, 102, 102, 0.35);
  padding: 2rem;
`

const StrokeSpan = styled(StyledSpan)`
  -webkit-text-stroke: 0.05px #08b9c5;
`

const DropShadowP = styled(StyledP)`
  text-shadow: 2px 2px 6px black;
`

const HeroSection = () => {
  return (
    <HeroImage className="bg-center h-auto mx-auto max-w-screen-xl px-4sm:px-6">
      <div className="text-center">
        <StyledH2 className="text-4xl tracking-tight leading-10 font-extrabold text-white sm:text-5xl sm:leading-none md:text-6xl">
          Cortiz <br className="sm:hidden" />
          <StrokeSpan className="text-primary-500">Fitness</StrokeSpan>
        </StyledH2>
        <DropShadowP className="mt-3 max-w-md mx-auto font-semibold text-white sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          These services are focused on channeling your best self. Whether
          you&apos;re a ruthless athlete ready to take it to the next level, a
          teen trying to make fitness fun, a mother or father pursing a better
          physique, or an individual struggling to workout and need motivation,
          then Cortiz Fitness is for you.
        </DropShadowP>
        <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
          <div className="rounded-md shadow">
            <StyledLink
              to={EndPoint.CORTIZ_FITNESS_PRICING}
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:shadow-outline-primary transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
            >
              Sign up
            </StyledLink>
          </div>
        </div>
      </div>
    </HeroImage>
  )
}

const ServicesSection = () => (
  <div className="bg-gray-50 overflow-hidden">
    <div className="relative max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <svg
        className="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
        width="404"
        height="784"
        fill="none"
        viewBox="0 0 404 784"
      >
        <defs>
          <pattern
            id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              className="text-gray-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width="404"
          height="784"
          fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)"
        />
      </svg>

      <div className="relative lg:grid lg:grid-cols-3 lg:col-gap-8">
        <div className="lg:col-span-1">
          <h3 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            A life changing experience.
          </h3>
        </div>
        <div className="mt-10 sm:grid sm:grid-cols-2 sm:col-gap-8 sm:row-gap-10 lg:col-span-2 lg:mt-0">
          <div>
            <div className="flex shadow-lg items-center justify-center h-12 w-12 rounded-md bg-primary-700 text-white">
              <svg
                className="h-6 w-6"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
              >
                <path d="M11.978 17c-.948.011-1.529.896-1.978 1.608a278.009 278.009 0 01-2.909 4.529c-.312.475-.65.859-1.219.863-.57.004-1.052-.685-.777-1.265C5.826 21.196 9 15.658 9 11c0-1.613-.858-2-2-2H3a1 1 0 010-2h18a1 1 0 010 2h-4c-1.141 0-2 .387-2 2 0 4.658 3.175 10.196 3.906 11.735.275.58-.207 1.269-.777 1.265-.569-.004-.908-.388-1.219-.863A284.737 284.737 0 0114 18.608c-.451-.717-1.038-1.611-2-1.608h-.022zM12 0a3 3 0 110 6 3 3 0 010-6" />
              </svg>
            </div>
            <div className="mt-5">
              <h5 className="text-lg leading-6 font-medium text-gray-900">
                Personal Training
              </h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
                This training service focuses on achieving your personal goals.
                We&apos;ll concentrate solely on pushing your limits during
                these one on one sessions so that we can channel your best self.
              </p>
            </div>
          </div>
          <div className="mt-10 sm:mt-0">
            <div className="flex shadow-lg items-center justify-center h-12 w-12 rounded-md bg-primary-700 text-white">
              <svg
                className="h-6 w-6"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
              >
                <path d="M12 22.246c-.428 0-1.099-.709-1.688-2.276-.579.295-1.082.527-1.571.738C9.508 22.7 10.599 24 12 24c1.44 0 2.552-1.371 3.322-3.452-.485-.201-.997-.43-1.581-.719-.599 1.667-1.298 2.417-1.741 2.417zM7.291 20.03c-3.099 1.139-6.29 1.168-6.29-1.644 0-.939.435-2.257 1.796-4.082a23.157 23.157 0 011.937-2.248c1.531-1.555 3.256-3.05 5.505-4.599-1.083-.596-2.264-1.167-3.416-1.59-1.18-.435-2.219-.676-3.015-.676-.508 0-.886.107-1.009.288-.133.192-.138.784.445 1.843l.21-.013c1.047 0 1.898.866 1.898 1.933 0 1.067-.851 1.931-1.898 1.931-1.048 0-1.897-.864-1.897-1.931 0-.346.089-.67.245-.951C1.212 7.251 1 6.431 1 5.788c0-1.515 1.154-2.354 2.808-2.354 2.514 0 5.9 1.662 8.082 2.946 2.214-1.363 5.717-3.159 8.304-3.159 1.893 0 2.807 1.054 2.807 2.395 0 .939-.436 2.256-1.796 4.08-3.084 4.137-9.216 8.606-13.914 10.334zm14.917-4.305c.142-.27.22-.576.22-.902 0-1.068-.849-1.933-1.897-1.933s-1.897.865-1.897 1.933c0 1.066.849 1.93 1.897 1.93l.258-.017c.552 1.024.544 1.597.415 1.787-.124.181-.501.287-1.01.287-1.602 0-3.833-.944-5.27-1.657-.48.342-1.075.748-1.657 1.119 1.925 1.036 4.757 2.295 6.927 2.295 1.64 0 2.808-.83 2.808-2.354 0-.638-.211-1.455-.794-2.488zm-12.834.119h-.001l-.013-.01a38.251 38.251 0 01-3.317-2.631c-2.817 2.877-3.611 4.963-3.238 5.524.126.189.492.299 1.003.299 2.35 0 6.08-2.018 8.287-3.465 2.709-1.775 5.8-4.328 7.736-6.926 1.506-2.018 1.552-3.081 1.366-3.361-.126-.19-.491-.298-1.003-.298-1.952 0-4.924 1.459-6.636 2.447a40.109 40.109 0 013.52 2.616c-.408.415-.834.819-1.27 1.211a38.262 38.262 0 00-3.901-2.81 37.657 37.657 0 00-4.603 3.552 37.087 37.087 0 003.721 2.873 30.73 30.73 0 01-1.651.979zM12 10a2 2 0 11-.001 4.001A2 2 0 0112 10zm-1.739-5.828a26.025 26.025 0 00-1.581-.718C9.45 1.372 10.562 0 12 0c1.403 0 2.495 1.302 3.261 3.292-.49.212-.996.447-1.572.739-.587-1.567-1.258-2.275-1.689-2.275-.441 0-1.139.75-1.739 2.416z" />
              </svg>
            </div>
            <div className="mt-5">
              <h5 className="text-lg leading-6 font-medium text-gray-900">
                Nutritional services
              </h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
                Look & feel great with the right diet for your lifestyle. In
                order to live healty and be healty, you have to eat healthy so
                we&apos;ll focus on improving your eating habits.
              </p>
            </div>
          </div>
          <div className="mt-10 sm:mt-0">
            <div className="flex shadow-lg items-center justify-center h-12 w-12 rounded-md bg-primary-700 text-white">
              <svg
                className="h-6 w-6"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
              >
                <path d="M20.624 22.474L19 21.605l-1.625.869.324-1.813-1.328-1.277 1.825-.252.804-1.658.804 1.658 1.825.252-1.329 1.277.324 1.813zm-7 0L12 21.605l-1.625.869.325-1.813-1.329-1.277 1.825-.252.804-1.658.804 1.658 1.825.252-1.329 1.277.324 1.813zm-7 0L5 21.605l-1.625.869.325-1.813-1.329-1.277 1.825-.252L5 17.474l.804 1.658 1.825.252L6.3 20.661l.324 1.813zM23 15.974H1a1 1 0 00-1 1v6a1 1 0 001 1h22a1 1 0 001-1v-6a1 1 0 00-1-1zm-22.998-1H5c.008-1.214-.001-2.289 0-3.013.005-3.993 1.749-3.116 1.749-6.663 0-1.507-.983-2.324-2.248-2.324-1.869 0-3.169 1.787-1.399 5.129.581 1.099-.62 1.359-1.91 1.657C.074 10.018 0 10.807 0 11.753l.002 3.221zM22.808 9.76c-1.29-.298-2.491-.558-1.91-1.657 1.77-3.342.47-5.129-1.399-5.129-1.265 0-2.248.817-2.248 2.324 0 3.324 1.719 2.704 1.749 6.676.008.972-.009 1.311 0 3h4.998L24 11.753c0-.946-.074-1.735-1.192-1.993zm-4.811 5.214H6.002L6 11.848c0-1.258.1-2.482 1.588-2.826 1.684-.389 3.344-.736 2.545-2.209-2.366-4.364-.674-6.839 1.866-6.839 2.491 0 4.226 2.383 1.866 6.839-.775 1.464.826 1.812 2.545 2.209 1.49.344 1.589 1.569 1.589 2.829l-.002 3.123z" />
              </svg>
            </div>
            <div className="mt-5">
              <h5 className="text-lg leading-6 font-medium text-gray-900">
                Group classes
              </h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
                Held every Monday, Wednesday and Friday at 6pm at the personal
                training studio. Each class is different from the others to
                provide a one of a kind total body workout. We modify every
                class from beginners to elite workout gurus to ensure the best
                results FOR YOU!
              </p>
            </div>
          </div>
          <div className="mt-10 sm:mt-0">
            <div className="flex shadow-lg items-center justify-center h-12 w-12 rounded-md bg-primary-700 text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="white"
              >
                <path d="M10.381 21.275l-6.631-3.787 1.391-2.384 6.631 3.787-1.391 2.384zm7.82-19.68c-1.957-1.134-3.641-1.665-5.032-1.588-2.093.117-3.933 1.233-3.621 4.89-.394-2.267-1.817-4.178-3.5-3.869-2.234.41-1.649 3.302-1.435 5.706.191 2.155.408 4.598-.7 6.502l-2.913 4.991 10.11 5.773 2.907-4.984c1.429-2.435 3.055-3.631 4.63-4.789 1.068-.787 4.353-2.998 4.353-6.193 0-2.645-2.251-4.962-4.799-6.439" />
              </svg>
            </div>
            <div className="mt-5">
              <h5 className="text-lg leading-6 font-medium text-gray-900">
                Sport Specific or Athletic Training
              </h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
                Being an athlete myself, I&apos;ve many years of experience
                training pros and developing kids skills. Are you a pro or
                college athlete looking to push your limits to exceed your
                greatness? Want to take your kids baseball or football skills to
                the next level? Look no further.
              </p>
            </div>
          </div>
          <div className="mt-10 sm:mt-0">
            <div className="flex shadow-lg items-center justify-center h-12 w-12 rounded-md bg-primary-700 text-white">
              <svg
                className="h-6 w-6"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
              >
                <path d="M18 0c-3.17 0-6 2.115-6 5.004 0 1.025.369 2.033 1.025 2.812.023.914-.515 2.232-.996 3.188 1.3-.234 3.146-.756 3.984-1.271 4.624 1.124 7.987-1.7 7.987-4.729 0-2.906-2.848-5.004-6-5.004zm-2.502 5.754c-.41 0-.75-.34-.75-.75 0-.416.34-.75.75-.75.416 0 .75.334.75.75 0 .41-.334.75-.75.75zm2.502 0c-.416 0-.75-.34-.75-.75 0-.416.334-.75.75-.75s.75.334.75.75c0 .41-.334.75-.75.75zm2.502 0c-.416 0-.75-.34-.75-.75 0-.416.334-.75.75-.75.41 0 .75.334.75.75 0 .41-.34.75-.75.75zm-20.502 6.371c0-3.934 3.191-7.125 7.125-7.125 1.041 0 2.027.229 2.918.63.08.802.312 1.576.68 2.288-.969-.83-2.225-1.335-3.598-1.335-3.056 0-5.542 2.485-5.542 5.542s2.485 5.542 5.542 5.542c2.782 0 5.084-2.062 5.476-4.736.45-.087 1.02-.216 1.627-.384-.219 3.737-3.312 6.703-7.104 6.703-3.933 0-7.124-3.191-7.124-7.125zm11.328 7.492l2.922 4.383h-14.25l2.922-4.383c.449.282.928.519 1.431.708l-1.394 2.092h8.332l-1.394-2.092c.503-.189.982-.426 1.431-.708zm-7.37-7.492c0 1.75 1.417 3.167 3.167 3.167s3.167-1.417 3.167-3.167-1.417-3.167-3.167-3.167-3.167 1.417-3.167 3.167zm4.75 0c0 .873-.711 1.583-1.583 1.583s-1.583-.711-1.583-1.583.711-1.583 1.583-1.583 1.583.71 1.583 1.583z" />
              </svg>
            </div>
            <div className="mt-5">
              <h5 className="text-lg leading-6 font-medium text-gray-900">
                Live Remote Streaming Sessions
              </h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
                This program offers virtual professional guidance at your
                fingertips. We&apos;ll take what you have and give you a unique
                live workout in your own home, gym or park. Great for staying
                fit while social distancing.
              </p>
            </div>
          </div>
          <div className="mt-10 sm:mt-0">
            <div className="flex shadow-lg items-center justify-center h-12 w-12 rounded-md bg-primary-700 text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="white"
              >
                <path d="M15.75 18.805c-2.435-.564-5.204-2.006-4.107-4.086 3.341-6.333.886-9.719-2.642-9.719-3.599 0-5.994 3.516-2.644 9.719 1.131 2.092-1.724 3.534-4.107 4.086-2.175.504-2.257 1.588-2.25 3.484l.004.711h17.993l.003-.689c.007-1.909-.066-3-2.25-3.506zm-1.546-7.805c.397-.869.838-2.063.815-2.895-.535-.71-.837-1.625-.837-2.556 0-2.628 2.316-4.549 4.909-4.549 2.577 0 4.909 1.907 4.909 4.549 0 2.751-2.754 5.32-6.532 4.298-.686.468-2.2.939-3.264 1.153zm4.185-6.371l-1.592.22 1.159 1.114-.283 1.582 1.418-.758 1.417.758-.282-1.582 1.159-1.114-1.593-.22-.701-1.447-.702 1.447z" />
              </svg>
            </div>
            <div className="mt-5">
              <h5 className="text-lg leading-6 font-medium text-gray-900">
                Custom Tailored Workout Plans
              </h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
                Interested in a 6, 8, 12 or 16 week workout plan? All plans
                include a brief nutrition consultation. Optionally, you may add
                customized nutritional plan. All plans are tailored towards your
                fitness goals.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const Testimonial = () => (
  <div className="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-10">
    <div className="relative max-w-screen-xl mx-auto px-4 sm:px-12 lg:px-32">
      <div className="relative">
        <blockquote className="mt-8 bg-white rounded shadow-lg p-4">
          <div className="max-w-3xl mx-auto text-center text-xl leading-6 font-medium text-gray-900">
            <p>
              &ldquo;I came to Christian when I was at 340 pounds and after 4
              months I went down to 280 lb. I just want to say to him thank you
              and I will hope to see him soon, he is the best, he has helped my
              self-esteem and he has made a tremendous impact in my life. Great
              motivator and he is a nutritionist.&rdquo;
            </p>
          </div>
          <footer className="mt-4">
            <div className="md:flex md:items-center md:justify-center">
              <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                <div className="text-base leading-6 font-medium text-gray-900">
                  Angel L. Berrios
                </div>

                <svg
                  className="hidden md:block mx-1 h-5 w-5 text-primary-700"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M11 0h3L9 20H6l5-20z" />
                </svg>

                <div className="text-base leading-6 font-medium text-gray-500">
                  member since 2018
                </div>
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
    </div>
  </div>
)

const CallToActionSection = () => (
  <div className="relative bg-primary-800">
    <div className="h-56 bg-primary-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
      <img
        className="w-full h-full object-cover"
        src={MondayMadness}
        alt="Support team"
      />
    </div>
    <div className="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
      <div className="md:ml-auto md:w-1/2 md:pl-10">
        <h2 className="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
          Are you game for Monday Madness!?
        </h2>
        <p className="mt-3 text-lg leading-7 text-gray-300">
          Take your aggression out on this class. In this class be prepared to
          flip tires, throw sandbags, slam a sledgehammer, slam a ball etc. This
          fun high impact class is perfect for anyone bored of thier normal
          routine or just want a fun stress release workout
        </p>
        <div className="mt-4 text-base leading-6 font-semibold uppercase tracking-wider text-gray-300">
          Start the week off on a strong note
        </div>
        {/* <div className="mt-8">
          <div className="rounded-md shadow">
            <StyledLink
              to="/"
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-accent-400 hover:bg-accent-500 focus:outline-none focus:shadow-outline-primary transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
            >
              Register now
            </StyledLink>
          </div>
        </div> */}
      </div>
    </div>
  </div>
)

export const CfHome = () => {
  const springOpacity = useSpring({
    from: { opacity: 0, transform: 'translateX(90px)' },
    to: { opacity: 1, transform: 'translateX(0)' },
  })
  return (
    <Layout seoTitle="Cortiz Fitness Home">
      <animated.div style={springOpacity}>
        <HeroSection />
        <ServicesSection />
        <Testimonial />
        <CallToActionSection />
      </animated.div>
    </Layout>
  )
}

export default CfHome
